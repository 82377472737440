.phone-input-wrapper {
}

.phone-input-wrapper input {
  @apply w-full text-sm rounded-[15px] px-[16px] py-[15px] border outline-none border-[#e2e8f0];
}

.phone-input-label {
  @apply text-sm text-[#333] mb-1.5;
}
