@use 'sass:map';

$colors: (
        'active': #48bb78,
        'draft': #485abb,
        'in-moderation': #d6d145,
        'unpaid': #9148BB,
        'rejected': #c73434,
        'archived': #a9a9a9,
);


.property-status {
  @each $name, $color in $colors {
    &--#{$name} {
      background-color: $color;
    }
  }
}
