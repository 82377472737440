.pincode-input-container .pincode-input-text {
  @apply border border-disabled;
}

.pincode-input-container .pincode-input-text[value]:not([value=""]) {
  @apply border-primary;
}

.error-wrapper .pincode-input-container .pincode-input-text, .error-wrapper .pincode-input-container .pincode-input-text[value]:not([value=""]) {
  @apply border-red-400;
}