/*todo add scss*/

.input {
  @apply w-full text-sm rounded-[15px] px-[16px] py-[15px] border outline-none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type=number] {
  -moz-appearance: textfield;
}

.input:disabled {
  /*@apply text-base-greyscale-600;*/
}

.label {
  @apply text-sm text-[#333] mb-1.5;
}
