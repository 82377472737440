.pincode-input-container {
  display: flex;
  justify-content: center;
  gap: 10px
}

.pincode-input-container .pincode-input-text{
  /*@apply w-[50px] h-[50px] bg-[#f5f5f5] text-[#666666] border border-[#aaaaaa80] font-semibold rounded-[8px];*/
  height: 50px;
  width: 50px;
  border-radius: 8px;
  border: 1px solid #aaaaaa80;
  background-color: #f5f5f5;
  font-weight: 600;
  color: #666666;
  margin: 0;
}

.error-wrapper .pincode-input-container .pincode-input-text{
  border: 1px solid #e53e3e;
}
