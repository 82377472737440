@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .flex-center {
    @apply flex justify-center items-center;
  }

  .text-display-1 {
    @apply text-[90px] leading-[90px] font-bold;
  }

  .text-display-2 {
    @apply text-[72px] leading-[80px] font-bold;
  }

  .text-display-3 {
    @apply text-[44px] leading-[50px] font-bold;
  }

  .text-display-4 {
    @apply text-[28px] leading-[38px] font-bold;
  }

  .text-heading-1 {
    @apply text-[56px] leading-[66px] font-bold;
  }

  .text-heading-2 {
    @apply text-[36px] leading-[46px] font-bold;
  }

  .text-heading-3 {
    @apply text-[24px] leading-[34px] font-bold;
  }

  .text-heading-4 {
    @apply text-[22px] leading-[28px] font-bold;
  }

  .text-heading-5 {
    @apply text-[18px] leading-[24px] font-bold;
  }

  .text-heading-6 {
    @apply text-[16px] leading-[22px] font-bold;
  }

  .text-body-large {
    @apply text-[22px] leading-[36px] font-normal;
  }

  .text-body-default {
    @apply text-[18px] leading-[24.6px] font-normal;
  }

  .text-body-small {
    @apply text-[16px] leading-[21.9px] font-normal;
  }

  .text-body-smaller {
    @apply text-[14px] leading-[16px] font-normal;
  }

  .text-400 {
    @apply text-[22px] leading-[22px];
  }

  .text-300 {
    @apply text-[20px] leading-[20px];
  }

  .text-200 {
    @apply text-[18px] leading-[24.6px];
  }

  .text-100 {
    @apply text-[16px] leading-[18px];
  }

  .text-bold {
    @apply text-[18px] leading-[24.6px] font-bold;
  }

  .text-link {
    @apply text-[18px] text-accent leading-[24.6px] font-bold underline;
  }
}

html {
  box-sizing: border-box;
  font-family: Manrope, sans-serif;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}


/*google maps hide footer*/
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}
.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display:none;
}
.gmnoprint div {
  background:none !important;
}

/*google maps remove blue border on focus*/
.gm-style > div {
  border: none !important;
}
