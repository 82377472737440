.button {
  @apply text-sm p-[10px] border-2 cursor-pointer rounded-lg min-w-[72px];
}

.button:disabled {
  @apply opacity-60 cursor-not-allowed;
}

.button-blue {
  @apply bg-blue text-white border-blue;
}

.button-red {
  @apply bg-[#E53E3E] text-white border-[#E53E3E];
}

.button-white {
  @apply bg-white text-black border-blue;
}

.button-small {
  @apply text-xs p-[8px];
}

.button-medium {
  @apply text-sm p-[10px];
}

.button-large {
  @apply text-base p-[12px];
}
